import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="mb-0 text-center">
         <div className="d-flex align-items-center justify-content-center pb-5">
          <div className="col-md-6">
            <p className="mb-3 mb-md-0">© 2023 <a className="text-dark fs-6" href="https://dnaut.cl" target="_blank" rel="noreferrer">
              DNAut
              </a>. Todos los derechos reservados.
            </p>
          </div>
        </div> 
      </footer>
    </>
  );
};

export default Footer;
