import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import axios from 'axios';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";

const Products = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(null)
  let componentMounted = true;

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product))
  }

  function formatoMoneda(numero) {
    // Formatear el número como moneda chilena (CLP)
    if(numero!=undefined){
      const numeroFormateado = numero.toLocaleString('es-CL', {
        style: 'currency',
        currency: 'CLP',
      });
    
      return numeroFormateado
    }
    
  }

  useEffect(() => {
    const getProducts = async () => {

      setLoading(true);
      
      axios(process.env.REACT_APP_URL_BASE_ENDPOINTS+'api/v1/products').then((response_products) => { 
        if (componentMounted) {
          setData(response_products.data.body);
          setFilter(response_products.data.body);

          axios(process.env.REACT_APP_URL_BASE_ENDPOINTS+'api/v1/categories').then((response_categories) => { 
            setCategories(response_categories.data.body);
            setLoading(false);
          })
        }
      })


      return () => {
        componentMounted = false;
      };
    };

    getProducts();
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = data.filter((item) => item.category === cat);
    setFilter(updatedList);
  }
  const ShowProducts = () => {
    return (
      <>
        <div className="buttons text-center py-5">
          <button className="btn btn-outline-dark btn-sm m-2" onClick={() => setFilter(data)}>Todos</button>
          {categories !== null ? (
            categories.map((item, index) => (
              <button key={index} className="btn btn-outline-dark btn-sm m-2" onClick={() => filterProduct(item.name)}>{item.name}</button>
            ))
          ) : null}
        </div>

        {filter.map((product) => {
          return (
            <div id={product.product_id} key={product.product_id} className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
              <div className="card text-center h-100" key={product.product_id}>
                <img
                  className="card-img-top p-3"
                  src={product.image}
                  alt="Card"
                  height={300}
                />
                <div className="card-body">
                  <h5 className="card-title">
                    {product.title.substring(0, 12)}...
                  </h5>
                  <p className="card-text">
                    {product.description.substring(0, 90)}...
                  </p>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item lead">{formatoMoneda(product.price)}</li>
                  <li className="list-group-item">Stock: {product.stock}</li>
                </ul>
                <div className="card-body">
                  <Link to={"/product/" + product.product_id} className="btn btn-dark m-1">
                    Comprar ahora
                  </Link>
                  <button className="btn btn-dark m-1" onClick={() => addProduct(product)}>
                    Añadir al carrito
                  </button>
                </div>
              </div>
            </div>

          );
        })}
      </>
    );
  };
  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Últimos productos</h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
    </>
  );
};

export default Products;
