import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { format } from 'date-fns';

const Cart = () => {
  const state = useSelector((state) => state.handleCart);
  const dispatch = useDispatch();
  const [inputDiscount, setInputDiscount] = useState('');
  const [codigosDescuento, setCodigosDescuento] = useState([])
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageFlag, setErrorMessageFlag] = useState(null);
  const [codigoAplicado, setCodigoAplicado] = useState(false)
  const [descuento, setDescuento] = useState(0)
  const [totalFinal, setTotalFinal] = useState(0)

  useEffect(() => {
    const getDiscounts = async () => {
      axios(process.env.REACT_APP_URL_BASE_ENDPOINTS+'api/v1/discounts').then((response_discounts) => {
        setCodigosDescuento(response_discounts.data.body);
      })
    };

    getDiscounts();
  }, []);

  function formatoMoneda(numero) {
    // Formatear el número como moneda chilena (CLP)
    if(numero!=undefined){
      const numeroFormateado = numero.toLocaleString('es-CL', {
        style: 'currency',
        currency: 'CLP',
      });
    
      return numeroFormateado
    }
  }

  function fechaActual() {
    const fechaYHoraActual = new Date();
    const dia = String(fechaYHoraActual.getDate()).padStart(2, '0');
    const mes = String(fechaYHoraActual.getMonth() + 1).padStart(2, '0');
    const año = fechaYHoraActual.getFullYear();
    const horas = String(fechaYHoraActual.getHours()).padStart(2, '0');
    const minutos = String(fechaYHoraActual.getMinutes()).padStart(2, '0');
    const segundos = String(fechaYHoraActual.getSeconds()).padStart(2, '0');
    return `${dia}-${mes}-${año} ${horas}:${minutos}:${segundos}`;
  }

  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">El carrito está vacío</h4>
            <Link to="/" className="btn  btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Continuar comprando
            </Link>
          </div>
        </div>
      </div>
    );
  };



  const postMercadopago = () => {

    const fechaYHoraActual = fechaActual()

    const data = {description: fechaYHoraActual, price: parseInt(totalFinal - descuento), quantity: 1, currency_id: 'CLP'}

    axios.post(process.env.REACT_APP_URL_BASE_ENDPOINTS+'api/v1/payment', data).then((response) => {
      window.location.href = response.data.init_point;
    })
  };

  const addItem = (product) => {
    dispatch(addCart(product));
  };
  const removeItem = (product) => {
    dispatch(delCart(product));
  };

  const ShowCart = () => {
    let subtotal = 0;
    let totalItems = 0;
    state.map((item) => {
      return (subtotal += item.price * item.qty);
    });
    setTotalFinal(subtotal)

    state.map((item) => {
      return (totalItems += item.qty);
    });

    const aplicarDescuento = () => {
      const encontrarDescuento = codigosDescuento.some((codigo) => codigo.code === inputDiscount);
      if (encontrarDescuento) {
        const codigoFinal = codigosDescuento.filter((codigo) => codigo.code === inputDiscount);
        if(totalFinal >= codigoFinal[0].min){
          setCodigoAplicado(true)
          setDescuento(codigoFinal[0].discount)
          setErrorMessage('Descuento aplicado');
          setErrorMessageFlag(true);
        }else{
          setCodigoAplicado(false)
          setErrorMessage('El monto mínimo debe ser de '+ formatoMoneda(codigoFinal[0].min));
          setErrorMessageFlag(false);
        }
      } else {
        setCodigoAplicado(false)
        setErrorMessage('Código incorrecto');
        setErrorMessageFlag(false);
      }
    };

    return (
      <>
        <section className="h-100 gradient-custom">
          <div className="container py-5">
            <div className="row d-flex justify-content-center my-4">
              <div className="col-md-8">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h5 className="mb-0">Lista de productos</h5>
                  </div>
                  <div className="card-body">
                    {state.map((item) => {
                      return (
                        <div key={item.id}>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-3 col-md-12">
                              <div
                                className="bg-image rounded"
                                data-mdb-ripple-color="light"
                              >
                                <img
                                  src={item.image}
                                  // className="w-100"
                                  alt={item.title}
                                  width={100}
                                  height={75}
                                />
                              </div>
                            </div>

                            <div className="col-lg-5 col-md-6">
                              <p>
                                <strong>{item.title}</strong>
                              </p>
                              <p>Stock: {item.stock}</p>
                            </div>

                            <div className="col-lg-4 col-md-6">
                              <div
                                className="d-flex mb-4"
                                style={{ maxWidth: "300px" }}
                              >
                                <button
                                  className="btn px-3"
                                  onClick={() => {
                                    removeItem(item);
                                  }}
                                >
                                  <i className="fas fa-minus"></i>
                                </button>

                                <p className="mx-5">{item.qty}</p>

                                <button
                                  className="btn px-3"
                                  disabled={item.qty >= item.stock} 
                                  onClick={() => {
                                    addItem(item);
                                  }}
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>

                              <p className="text-start text-md-center">
                                <strong>
                                  <span className="text-muted">{item.qty}</span>{" "}
                                  x {formatoMoneda(item.price)}
                                </strong>
                              </p>
                            </div>
                          </div>

                          <hr className="my-4" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4">
                  <div className="card-header py-3 bg-light">
                    <h5 className="mb-0">Resumen del pedido</h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                        Productos ({totalItems})<span>{formatoMoneda(Math.round(subtotal))}</span>
                      </li>
                      <li className="mt-2 list-group-item justify-content-between px-0 border-0">
                      <div className="row">
                        <div className="col-sm-8">
                          <input
                            type="text"
                            defaultValue={inputDiscount}
                            className="form-control"
                            onChange={(e) => setInputDiscount(e.target.value)}
                            placeholder="Código de descuento"
                            autoFocus
                          />
                        </div>

                        <div className="col-sm-4">
                          <button
                            className="w-100 btn btn-primary " onClick={aplicarDescuento}
                            disabled={codigoAplicado}
                          >
                            Aplicar
                          </button>
                        </div>
                        <div>
                        {errorMessageFlag == true ? <div style={{  height: '20px',color: 'green' }}>{errorMessage}</div> : (errorMessageFlag == false ? <div style={{ height: '20px', color: 'red' }}>{errorMessage}</div> : <div style={{ color: 'red', height: '20px', background: 'transparent'}}></div>)}
                        </div>
                      </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3 mt-3">
                        <div>
                          <strong>Monto total</strong>
                        </div>
                        <span>
                          <strong>{formatoMoneda(Math.round(totalFinal - descuento))}</strong>
                        </span>
                      </li>
                    </ul>
                    <button
                      className="w-100 btn btn-primary"
                      onClick={postMercadopago}
                    >
                      Proceder al Pago
                    </button> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Cart</h1>
        <hr />
        {state.length > 0 ? <ShowCart /> : <EmptyCart />}
      </div>
      <Footer />
    </>
  );
};

export default Cart;
